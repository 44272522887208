.about-assortment {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  margin-bottom: 106px;
  &__body {
    display: flex;

  }

  &__title {
    color: var(--blue-title);
    font-weight: 600;
  }

  &__text {
    font-weight: 500;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__item-img {
    object-fit: cover;
    border-radius: 50%;
  }

  &__item-text {
    color: var(--gray-text);
    font-weight: normal;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__pic {
    z-index: 1;
    position: relative;
    &::before {
      z-index: -1;
      content: "";
      position: absolute;
      border: 8px solid var(--green-border);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .about-assortment {
    margin-bottom: 106px;

    &__body {
      padding: 44px 71px 48px 0;
      justify-content: space-between;
    }

    &__title {
      font-size: 33px;
      line-height: 55px;
      margin-bottom: 16px;
    }

    &__text {
      font-size: 17px;
      line-height: 155%;
      margin-bottom: 50px;
    }

    &__item {
      justify-content: space-between;
      &:last-child {
        margin-top: 49px;
      }
    }

    &__item-pic {
      width: 115px;
      height: 115px;
    }

    &__item-text {
      flex: 0 0 71%;
      font-size: 16px;
      line-height: 155%;
    }

    &__wrapper {
      flex: 0 0 48%;
    }

    &__pic {
      width: 444px;
      height: 490px;
    }

    &__pic {
      &::before {
        top: -40px;
        right: -67px;
        height: 574px;
        width: 317px;
      }
    }
  }
}

@include respond-up('medium') {
  .about-assortment {
    &__body {
      flex-direction: row;
    }
  }
}

@include respond('medium') {
  .about-assortment {
    margin-bottom: 60px;

    &__body {
      padding: 28px 19px 27px 0;
      justify-content: space-around;
    }

    &__title {
      font-size: 28px;
      line-height: 135%;
      margin-bottom: 18px;
    }

    &__text {
      font-size: 16px;
      line-height: 155%;
      margin-bottom: 35px;
    }

    &__item {
      justify-content: flex-start;
      &:last-child {
        margin-top: 30px;
      }
    }

    &__item-text {
      flex: 0 0 61%;
      font-size: 15px;
      line-height: 155%;
    }

    &__wrapper {
      flex: 0 0 46%;
      margin-right: 41px;
    }

    &__pic {
      width: 319px;
      height: 352px;
    }

    &__pic {
      &::before {
        top: -28px;
        right: -40px;
        height: 413px;
        width: 228px;
      }
    }
  }
}

@include respond-down('medium') {
  .about-assortment {
    &__item-pic {
      width: 75px;
      height: 75px;
      margin-right: 20px;
    }
  }
}

@include respond-down('small') {
  .about-assortment {
    margin-bottom: 40px;

    &__body {
      padding-bottom: 44px;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    &__title {
      font-size: 24px;
      line-height: 125%;
      margin-bottom: 12px;
    }

    &__text {
      font-size: 15px;
      line-height: 155%;
      margin-bottom: 20px;
    }

    &__item {
      justify-content: flex-start;
      &:last-child {
        margin-top: 20px;
      }
    }

    &__item-text {
      flex: 0 0 66%;
      font-size: 15px;
      line-height: 155%;
    }

    &__pic {
      margin-top: 56px;
      width: 300px;
      height: 325px;
    }

    &__pic {
      &::before {
        top: -26px;
        right: -38px;
        height: 381px;
        width: 214px;
      }
    }
  }
}