.about-company {
  &__title {
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    color: var(--black-text);
  }

  &__main-text {
    color: var(--black-text);
  }

  &__text {
    line-height: 155%;
    color: var(--gray-text);
  }

  &__icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__advantages-list {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__icon {
    object-fit: contain;
  }
}

@include respond-up('large') {
  .about-company {
    margin-bottom: 131px;

    &__title {
      font-size: 40px;
      margin-bottom: 70px;
    }

    &__content {
      margin-bottom: calc(61px + 70px);
      display: grid;
      grid-template-columns: 1.08fr 1fr;
      grid-template-rows: auto;
      column-gap: 108px;
    }

    &__pic {
      max-height: 573px;
      grid-column: 1/2;
      grid-row: 1/4;
    }

    &__main-text {
      font-size: 17px;
      line-height: 155%;
      max-width: 481px;
      margin-top: 27px;

      grid-column: 2/3;
      grid-row: 1/2;
      margin-bottom: 30px;
    }

    &__img {
      box-shadow: -38px 60px 0px 0px var(--green-shadow);
    }

    &__text {
      font-size: 16px;
      margin-bottom: 25px;
    }

    &__advantages-list {
      width: 1095px;
      margin: 71px 0 0 13px;
    }

    &__item {
      max-width: 203px;
    }

    &__icon-wrapper {
      height: 59px;
      margin-bottom: 14px;
    }
  }
}

@include respond('medium') {
  .about-company {
    margin-bottom: 61px;
    &__title {
      font-size: 30px;
      margin-bottom: 35px;
    }

    &__content {
      display: grid;
      grid-template-columns: 10fr 21fr;
      column-gap: 36px;
      margin: 0 0 41px 0;
    }

    &__pic {
      margin-bottom: 33px;
      height: 306px;
      width: 296px;
      grid-column: 1/2;
      grid-row: 1/3;
    }

    &__img {
      object-fit: cover;
    }

    &__main-text {
      grid-column: 2/3;
      grid-row: 1/2;
      margin: 0 0 20px 0;
      font-size: 15px;
      line-height: 155%;
    }

    &__text {
      font-size: 16px;
      &:last-child {
        margin-top: 20px;
        grid-row: 3/4;
        grid-column: 1/3;
      }
    }

    &__item {
      max-width: 203px;
    }

    &__icon-wrapper {
      height: 59px;
      margin-bottom: 20px;
    }

    &__description {
      padding: 0 13px;
      text-align: center;
      font-size: 16px;
      line-height: 135%;
    }

  }
}

@include respond-down('medium') {
  .about-company {
    &__img {
      box-shadow: -40px 35px 0px 0px var(--green-shadow);
    }
  }
}

@include respond-down('small') {
  .about-company {
    margin-bottom: 40px;
    &__title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
    }

    &__pic {
      width: 296px;
      height: 306px;
      margin-bottom: 33px;
    }

    &__main-text {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    &__text {
      &:last-child {
        margin-top: 15px;
      }
    }

    &__advantages-list {
      flex-wrap: wrap;
    }

    &__item {
      flex: 0 0 50%;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    &__icon-wrapper {
      margin-bottom: 20px;
    }

    &__description {
      text-align: center;
    }

  }
}