.h1__delivery {
  margin-bottom: 40px;
}

.delivery {

  line-height: 155%;
  padding-bottom: 100px;

  &__main-information {
    margin-bottom: 50px;
  }

  &__information {
    padding-bottom: 60px;

    h3 {
      padding-bottom: 20px;
    }
  }

  &__pricing {
    background-color: var(--secondary-color);
    margin-bottom: 70px;
  }

  &__price-blocks {
    display: flex;
    justify-content: center;
    padding: 40px;
    flex-wrap: wrap;
  }

  &__price-blocks li + li::before {
    content: "";
    border-left: 1px solid #DCE3DC;
    height: 80px;
    width: 1px;

    position: absolute;
    left: -40px;
    top: -10px;
  }

  &__price-block {
    width: 33%;
    min-width: 200px;
    position: relative;
  }

  &__price {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  &__price-free {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 9px 0;
    text-align: center;
  }
}

@include respond('large') {
  .delivery {
    display: flex;
    justify-content: space-between;

    &__content {
      flex: 0 0 830px;
    }

    &__question-container {
      flex: 0 0 306px;
    }
  }
}

@include respond('small') {
  .h1__delivery {
    margin-bottom: 20px;
  }

  .delivery {

    padding-bottom: 30px;

    &__main-information {
      margin-bottom: 20px;
    }

    &__price-blocks {
      justify-content: left;
      padding: 25px;
      gap: 15px;
    }

    &__price-blocks li + li::before {
      display: none;
    }

    &__pricing {
      margin-bottom: 35px;
    }

    &__information {
      padding-bottom: 30px;

      h3 {
        padding-bottom: 15px;
      }
    }

  }
}