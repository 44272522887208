.contacts-info {
  &__text {

  }

  &__name {
    margin-bottom: 9px;
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 500;
  }

  &__value {

  }

  &__item {

  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    border: 1px solid #D0D6D0;
    align-items: center;

  }

  &__vk-button {
    border-right: 1px solid #D0D6D0;
    padding: 16px 18px 16px;
  }

  &__instagram-button {
    padding: 14px 17px 13px;
  }

}