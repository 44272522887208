.breadcrumbs {
  &__list {
    display: flex;
  }

  &__item {
    color: var(--grey-light-color);
    font-size: 13px;
    line-height: 16px;
    &_delimiter {
      padding: 0 5px;
    }
  }
}

@include respond-up('large') {
  .breadcrumbs {
    &__list {
      padding: 42px 0 60px;
    }
  }
}
@include respond('medium') {
  .breadcrumbs {
    &__list {
      padding: 20px 0 39px;
    }
  }
}
@include respond-down('small') {
  .breadcrumbs {
    &__list {
      padding: 20px 0;
    }
  }
}