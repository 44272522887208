.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-color);

  &__image {
    width: 55px;
    height: 73px;
    background: {
      size: cover;
      position: center;
      repeat: no-repeat;
    };
  }

  &__img {
    display: none;
  }

  &__name {
    font-weight: 400;
    line-height: 125%;
  }

  &__price-container {
    display: flex;
    align-items: flex-end;
  }

  &__price {
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
  }

  &__price-value {
    margin-left: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
  }

  &__quantity {
    border: 1px solid var(--grey-color);
    width: 149px;
    height: 51px;
    border-radius: 25px;
    padding: 8px 0;
  }

  &__up-down {
    display: flex;
    align-items: center;
  }

  &__up-down-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 35px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0);

    svg {
      pointer-events: none;
    }
  }

  &__up-down-input {
    text-align: center;
    flex: 49px;
    border: 1px solid var(--grey-color);
    border-top: 0px;
    border-bottom: 0px;
    padding: 7px 0px;
  }
}

@include respond-up('large') {
  .cart-item {
    padding: 20px 40px 20px 22px;

    &__name {
      font-size: 16px;
      flex: 0 0 302px;
    }

    &__counter {
      flex: 0 0 17%;
    }

    &__price-container {
      flex: 0 0 16%;
    }
  }
}

@include respond-down('medium') {
  .cart-item {
    padding: 15px;
    &__name {
      font-size: 16px;
      flex: 0 0 251px;
    }

    &__counter {
      flex: 0 0 15%;
    }

    &__price-container {
      flex: 0 0 20%;
    }

  }
}

@include respond-down('small') {
  .cart-item {
    position: relative;
    padding: 14px;
    flex-wrap: wrap;
    &__image {
      margin-bottom: 20px;
    }

    &__name {
      flex: 0 0 65%;
    }

    &__counter {
      flex: 0 0 40%;
    }

    &__price-container {
      flex: 0.8 0 21%;
    }

    &__price {
      text-align: left;
    }

    &__remove {
      position: absolute;
      top: 16px;
      right: 15px;
    }
  }
}