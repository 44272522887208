.floating-field {
  position: relative;

  label {
    position: absolute;
    height: var(--default-input-height);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: var(--text-color);
    font-size: 15px;
    line-height: 24px;
    transition: all 0.2s;
    margin: 0;
    background-color: transparent;
  }

  &._filled label,
  input:focus + label{
    font-size: 12px;
    color: var(--text-color-gray);
    top: 5px;
    height: 14px;
    background-color: transparent;
  }

  &._filled label,
  &.floating-field input:focus+label,
  &.floating-field textarea:focus+label {
    font-size: 13px;
    top: 0;
    height: 0;
    margin-bottom: 5px;
  }

  textarea:focus + label {
    top: -15px;
  }

  &>input[type="text"], &>input[type="password"], &>input[type="search"] {
    padding-top: 11px;
  }

  &>textarea {
    padding-top: 5px;

  }
}

@include respond-up('medium') {
  .floating-field {
    label {

    }
  }
}

@include respond-down('small') {
  .floating-field {
    label {
      //font-size: 16px;
      //height: var(--default-input-height);
    }
  }
}