.header {
  display: flex;
  justify-content: space-between;


  &__inner {
    display: flex;
    align-items: center;
  }

  &__number {
    font-weight: 600;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  &__list {
    display: flex;
  }

  &__item {
    font-weight: 500;

    &:hover {
      .header__link {
        &::before {
          left: 0;
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .3s cubic-bezier(.25, .1, .25, 1);
        }
      }
    }
  }

  &__link {
    display: inline-block;
    font-weight: 500;
    transition: all .4s;
    border-bottom: 1px solid transparent;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: black;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .3s ease-out;
    }

  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__basket {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__basket-count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 18px;
    left: 47px;
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 100%;
    font-weight: 600;
    font-size: 8px;
    line-height: 100%;
    color: var(--primary-color);

    &_hide {
      display: none;
    }
  }

}

@include respond-up('large') {
  .header {

    &__mobile-block {
      display: none;
    }

    &__logo {
      padding: 0 50px 0 0;
    }

    &__number {
      font-size: 16px;
      line-height: 26px;
      margin-right: 30px;
    }

    &__button-wrapper {
      margin-right: 60px;
    }

    &__list {
      display: flex;
      margin: 0 -12px;
    }

    &__item {
      padding: 0 12px;
    }

    &__link {
      font-size: 14px;
      line-height: 26px;
    }

    &__button {
      margin-bottom: 5px;
      color: var(--primary-color);
      border-bottom: 1px solid var(--primary-color);

    }

    &__basket {
      width: 80px;
      height: 75px;
    }

    &__basket-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__basket-count {
      top: 18px;
      left: 47px;
    }

    &__mobile-form {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .header {
    margin: 0 -20px;

    &__mobile-header {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-right: 50px;

      &._opened {

        .header__icon-item {
          background-color: black;

          &_top {
            background-color: black;
            top: calc(50% - 5px);
            transform: rotate(45deg);
          }

          &_bottom {
            background-color: black;
            bottom: calc(50% - 1px);
            transform: rotate(-45deg);
          }
        }
      }
    }

    &__icon-item {
      position: absolute;
      transform-origin: center;
      transition: {
        duration: .2s;
        timing-function: ease-in-out;
      };
      width: 30px;
      height: 2px;
      background-color: black;
      display: block;
      margin: 2px 0;

      &_top {
        top: calc(50% - 10px);
        transition-property: top, transform;
      }

      &_bottom {
        bottom: calc(50% - 10px);
        transition-property: bottom, transform;
      }
    }

    &__img {
      width: 101px;
      height: 37px;
      object-fit: cover;

    }

    &__menu-wrapper {
      display: none;
    }

    &__button {
      display: none;
    }

    &__mobile-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: -10px 0;
    }

    &__mobile-item {
      flex: 0 0 50%;
      padding: 10px 0;
    }

    &__mobile-link {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;

    }

    &__expand-block {
      padding: 20px 18px;
      pointer-events: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: absolute;
      top: 54px;
      left: 0;
      opacity: 0;
      width: 100%;
      background-color: var(--t);
      transition: var(--default-transition);
      font-weight: 900;
      font-size: 16px;
      line-height: 150%;
      align-items: center;

      &._opened {
        opacity: 1;
        pointer-events: auto;
        z-index: 11;
        width: 100%;
        background-color: var(--background-color);

      }
    }
  }
}

@include respond('medium') {
  .header {

    &__number {
      font-size: 15px;
      line-height: 26px;
      margin-right: 30px;
    }

    &__mobile-header {
      padding-left: 40px;
    }

    &__mobile-form {
      margin-right: 60px;
      padding-left: 30px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        height: 100%;
        width: 1px;
        background-color: var(--grey-color);
      }
    }

    &__basket {
      width: 80px;
      height: 62px;
    }

    &__basket-count {
      top: 11px;
      left: 47px;
    }
  }
}

@include respond-down('small') {
  .header {
    &__logo-wrapper {
      padding: 6px 0;
    }

    &__number {
      display: none;
    }

    &__list {
      display: flex;
    }

    &__mobile-block {
      padding-left: 19px;
    }

    &__basket {
      width: 66px;
      height: 100%;
    }

    &__mobile-form {
      margin-right: 26px;
    }

    &__basket-count {
      top: 6px;
      left: 41px;
    }

  }
}