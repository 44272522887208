.question {
  background: var(--green-shadow);
  padding: 28px;

  &__heading {
    font-size: 23px;
    padding-bottom: 20px;
    font-weight: 600;
  }
}

@include respond('medium') {
  .question {
    display: flex;
    align-items: center;

    &__heading {
      padding-right: 60px;
      padding-bottom: 0;
    }
  }
}