.bottom-form {

  &__wrapper {
    background-color: var(--green-shadow);
    display: flex;
    position: relative;

  }

  &__title {
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    color: var(--grey-text-color);
    line-height: 155%;

  }

  &__image {
    position: absolute;
    bottom: 0;
    left: 30%;
  }

  &__field {
    input[type="text"] {
      border: none;
      background-color: transparent;
      --default-input-height: 40px;
      border-bottom: 1px solid black;
    }

    &_placeholder {
      input[type='text'] {
        &:focus {
          animation: opacity 0.4s;
          transition: var(--default-transition);

          &::placeholder {
            color: black;
          }
        }

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  &__success-container {
    display: flex;
    align-items: center;

  }

  &__success-image {
    margin-right: 23px;
  }

  &__policy-link {
    color: black;
  }

  &__policy {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
    letter-spacing: 0;
    color: var(--grey-text-color);
  }

}

@include respond-up('large') {
  .bottom-form {
    padding-bottom: 100px;

    &__wrapper {
      padding: 60px 124px 60px 50px;
      justify-content: space-between;
    }

    &__title {
      font-size: 40px;
      margin-bottom: 12px;
    }

    &__inner {
      flex: 0 0 42%;
    }


    &__description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      max-width: 320px;

    }

    &__field {
      margin-bottom: 50px;
    }

    &__button-container {
      display: flex;
      align-items: center;
    }

    &__content {
      flex: 0 0 50%;
    }

    &__button {
      margin-right: 26px;
    }

    &__policy {
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0;
      color: #8C8E90;
    }

    &__policy-link {
      color: black;
    }
  }
}

@include respond('medium') {
  .bottom-form {
    padding-bottom: 50px;

    &__wrapper {
      padding: 40px 30px;
      justify-content: space-between;
    }

    &__title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    &__inner {
      flex: 0 0 35%;
    }

    &__description {
      font-size: 15px;
      color: var(--grey-text-color);

    }

    &__field {
      margin-bottom: 35px;
    }

    &__button-container {
      display: flex;
      flex-direction: column;
    }

    &__content {
      flex: 0 0 50%;
    }

    &__button {
      margin-bottom: 15px;
    }

    &__image {
      left: 25%;
    }
  }
}

@include respond-down('small') {
  .bottom-form {
    padding-bottom: 30px;

    &__wrapper {
      padding: 30px 20px;
      flex-direction: column;
      overflow: hidden;
    }

    &__title {
      font-size: 27px;
      margin-bottom: 12px;
    }

    &__description {
      font-size: 15px;
      margin-bottom: 20px;
    }

    &__field {
      margin-bottom: 30px;
    }

    &__button-container {
      display: flex;
      flex-direction: column;
    }

    &__content {
      flex: 0 0 50%;
    }

    &__button {
      margin-bottom: 15px;
    }

    &__image {
      left: 75%;
    }
  }
}