.catalog-page {
  &__main-title {
    line-height: 125%;
    font-weight: 600;
  }

  &__list {

  }

  &__items {

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__item {

  }

  &__container {

  }

  &__image {

  }

  &__wrapper {

  }

  &__title {
    display: inline-block;
    font-weight: bold;
  }

  &__price {
    display: inline-block;
    font-weight: 500;
  }
}

@include respond-up('large') {
  .catalog-page {
    &__main-title {
      font-size: 40px;
      margin-bottom: 54px;
    }

    &__list {

    }

    &__items {
      grid-row-gap: 66px;
      grid-column-gap: 32px;
      margin-bottom: 140px;
    }

    &__item {
      position: relative;

      &:nth-child(3n+2) {
        margin-top: 60px;
      }
    }

    &__image {

    }

    &__wrapper {

    }

    &__title {
      font-size: 16px;
    }

    &__price {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@include respond-down('medium') {
  .catalog-page {
    &__main-title {
      font-size: 30px;
      margin-bottom: 30px;
    }

    &__list {

    }

    &__items {

      grid-row-gap: 26px;
      grid-column-gap: 19px;
      margin-bottom: 74px;
    }

    &__item {
      &:nth-child(3n+2) {
        margin-top: 37px;
      }
    }


    &__image {

    }

    &__wrapper {

    }

    &__title {
      font-size: 13px;
    }

    &__price {
      font-size: 15px;
      line-height: 18px;
    }

  }
}

@include respond-down('small') {
  .catalog-page {
    &__main-title {
      margin-bottom: 20px;
    }

    &__list {

    }

    &__items {

      grid-template-columns: 1fr 1fr;
      grid-column-gap: 14px;
      margin-bottom: 40px;
    }

    &__item {
      &:nth-child(3n+2) {
        margin-top: 0;
      }
    }

    &__container {

    }

    &__image {

    }

    &__wrapper {

    }

    &__title {
      font-size: 12px;
    }

    &__price {
      font-size: 14px;
      line-height: 17px;
    }
  }
}