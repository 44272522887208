.form-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: 600;
    line-height: 125%;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    max-width: 420px;
    line-height: 155%;
    color: var(--second-grey-color);
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__field {
    input[type="text"] {
      border: none;
      background-color: transparent;
      --default-input-height: 40px;
      border-bottom: 1px solid black;
    }

    &_placeholder{
      input[type='text'] {
        &:focus {
          animation: opacity 0.4s;
          transition: var(--default-transition);

          &::placeholder {
            color: black;
          }
        }

        &::placeholder {
          color: transparent;
        }
      }
    }

  }

  &__policy {
    max-width: 320px;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
    letter-spacing: 0;
    color: var(--grey-text-color);
  }

  &__policy-link {
    color: black;
  }
}

@include respond-up('large') {
  .form-modal {
    padding: 60px 140px;

    &__title {
      font-size: 30px;
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 16px;
      margin-bottom: 40px;
    }

    &__field {
      width: 422px;
      margin-bottom: 50px;

      &:nth-child(2) {
        margin-bottom: 40px;
      }
    }

    &__button {
      margin-bottom: 15px;
    }
  }
}

@include respond-down('medium') {
  .form-modal {
    padding: 20px 10px;

    &__img {
      margin-bottom: 30px;
    }

    &__title {
      font-size: 27px;
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 15px;
      margin-bottom: 20px;
    }

    &__field {
      width: 100%;
      margin-bottom: 35px;

      &:nth-child(2) {
        margin-bottom: 30px;
      }
    }

    &__form {
      width: 100%;
    }

    &__button {
      margin-bottom: 15px;
    }
  }
}

@include respond-down('small') {
  .form-modal {
    padding: 30px 0px 20px;
  }
}
