.counter {
  width: 149px;
  height: 51px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--grey-color);
  border-radius: 25px;
  padding: 8px 0;

  &__button-minus {
    width: 49px;
    background: rgba(255, 255, 255, 0);
  }

  &__button-plus {
    width: 49px;
    background: rgba(255, 255, 255, 0);
  }

  &__count {
    width: 49px;
    border: 1px solid var(--grey-color);
    border-top: 0px;
    border-bottom: 0px;
    padding: 7px 0px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
  }
}