.office-input {
  width: 100%;
  margin-top: 25px;

  &__list {

  }

  &__item {

  }

  &__radio {
    display: none;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__name {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: black;
    flex: 0 0 100%;
    margin-bottom: 10px;
  }

  &__phone {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: black;
    //flex: 0 0 50%;
  }

  &__worktime {
    font-size: 14px;
    line-height: 125%;
    flex: 0 0 50%;
    text-align: right;

  }

  &__label {

  }

  &__name {

  }

  &__phone {

  }

  &__worktime {

  }

  &__schedule {

  }

  &__schedule-item {

  }

  &__day {

  }

  &__time {

  }
}

@include respond-up('large') {
  .office-input {

    &__list {

    }

    &__item {

    }

    &__radio {
    }

    &__label {
    }

    &__name {
      font-size: 18px;
    }

    &__phone {
    }

    &__worktime {
    }

    &__schedule {

    }

    &__schedule-item {

    }

    &__day {

    }

    &__time {

    }
  }
}

@include respond-down('medium') {
  .office-input {

    &__list {

    }

    &__item {

    }

    &__radio {
    }

    &__label {
    }

    &__name {
      font-size: 18px;
    }

    &__phone {
    }

    &__worktime {
    }

    &__schedule {

    }

    &__schedule-item {

    }

    &__day {

    }

    &__time {

    }
  }
}

@include respond-down('small') {
  .office-input {

    &__list {

    }

    &__item {

    }

    &__radio {
    }

    &__label {
    }

    &__name {
      font-size: 16px;
    }

    &__phone {
    }

    &__worktime {
      flex: none;
    }

    &__schedule {

    }

    &__schedule-item {

    }

    &__day {

    }

    &__time {

    }
  }
}