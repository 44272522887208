.block-promotion {
  &__body {
  }

  &__title {
    font-weight: 600;
    line-height: 125%;
    text-align: center;
  }

  &__text {
    line-height: 155%;
    color: var(--gray-text);
  }

  &__content {
    display: flex;

    &.tns-slide-active {
      opacity: 1;
    }

    &.fadeInDown {
      .block-promotion {
        &__container {
          animation: {
            name: default-slider-in-30;
            fill-mode: forwards;
            timing-function: var(--default-transition);
          };
        }
      }
    }

    &.fadeOutDown {
      .block-promotion {
        &__container {
          animation: {
            name: default-slider-out-30;
            fill-mode: forwards;
            timing-function: var(--default-transition);
          };
        }
      }
    }
  }

  &__container {
    display: flex;
  }

  &__content-wrapper {
    background-color: white;

  }

  &__list-wrapper {
    position: relative;
  }

  &__header {
    font-weight: 600;
    line-height: 130%;
  }

  &__img {
    object-fit: cover;
  }

  &__pic {
  }

  &__arrows {
    display: flex;
    position: absolute;
  }

  &__arrow_left{
    margin-right: 12px;
  }
}

@include respond-up('large') {
  .block-promotion {
    padding-bottom: 140px;
    &__body {

    }

    &__title {
      font-size: 40px;
      margin-bottom: 51px;
    }

    &__text {
      font-size: 16px;
      margin-bottom: 40px;
      max-width: 430px;
    }

    &__content-wrapper {
      padding: 70px 60px;
      height: 417px;
    }

    &__content-wrapper {
      width: 660px;
    }

    &__button {
      margin-bottom: 58px;
    }

    &__wrapper {
      margin-bottom: 28px;

    }

    &__header {
      font-size: 25px;
      margin-bottom: 25px;
    }

    &__arrows {
      bottom: -28px;
    }

    &__img {
      width: 650px;
      height: 440px;
    }

    &__pic {
      width: 650px;
      height: 440px;

      margin-top: 36px;
      margin-left: -100px;
    }
  }
}

@include respond('medium') {
  .block-promotion {
    padding-bottom: 80px;
    &__body {

    }

    &__title {
      font-size: 30px;
      margin-bottom: 30px;
    }

    &__text {
      font-size: 15px;
      margin-bottom: 25px;
      max-width: calc((309/768)*100vw);
    }

    &__content-wrapper {
      padding: 35px 25px;
      height: 270px;
    }

    &__content-wrapper {
      width: calc((415/768)*100vw);
    }

    &__button {

    }

    &__wrapper {
      margin-bottom: 28px;
    }

    &__header {
      font-size: 20px;
      margin-bottom: 15px;
    }

    &__arrows {
      bottom: -50px;
    }

    &__arrow_left{
      margin-right: 10px;
    }

    &__img {
      width: calc((323/768)*100vw);
      height: calc((218/768)*100vw);
    }

    &__pic {
      margin-top: calc((73/768)*100vw);
      margin-left: -50px;
      width: 42.05729vw;
      height: 28.38542vw;
    }
  }
}

@include respond-down('small') {
  .block-promotion {
    padding-bottom: 40px;

    &__title {
      font-size: 30px;
      text-align: left;
    }

    &__text {
      font-size: 15px;
      margin-bottom: 20px;
    }

    &__content-wrapper {
      padding: 46px 16px;
      order: 2;
    }

    &__container {
      flex-direction: column;
    }

    &__wrapper {
      margin-bottom: 15px;

    }

    &__header {
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__arrows {
      position: relative;
    }

    &__img {
      width: 100%;
      padding: 0 14px;
      height: calc((209/375)*100vw);
    }

    &__pic {
      margin-bottom: -25px;
      z-index: 10;
      order: 1;
    }
  }
}