.contacts {
  &__head {

  }


  &__info {
  }

  &__info-items {
    max-width: 795px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 53px;
  }

  &__info-item {
  }
}

@include respond('medium') {
  .contacts {
    &__head {

    }


    &__info {
    }

    &__info-items {
      max-width: 647px;
      margin-bottom: 28px;
    }

    &__info-item {

    }
  }

}

@include respond-down('small') {
  .contacts {
    &__head {

    }


    &__info {
    }

    &__info-items {
      display: flex;
      max-width: 647px;
      margin-bottom: 28px;
    }

    &__info-item {
      &:nth-child(1) {
        padding-bottom: 20px;
      }

      &:nth-child(2) {
        padding-bottom: 20px;
      }
    }
  }

}