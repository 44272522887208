.button {
  margin: 0;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  background: var(--primary-color);
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  padding: 16px 40px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 24px;
  color: #FFFFFF;


  &_expand, &_wide {
    width: 100%;
  }

  &_border {
    background-color: transparent;
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
    font-weight: 600;
    font-size: 11px;
    line-height: 100%;
    letter-spacing: 2px;
    padding: 0;
    text-transform: uppercase;
  }

}

@include respond-down('medium') {
  .button {
    padding: 13px 40px;
  }
}