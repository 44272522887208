.characters-container {
  flex: 0 0 65%;

  &__tab-name-description {
    margin-right: 50px;
    padding-bottom: 19px;
  }

  &__description {
    text-align: left;
  }

}