.arrow {
  background-color: transparent;
  border: 1px solid var(--light-green-color);
  border-radius: 1000px;
  width: 58px;
  height: 58px;


  &:hover {
    border-color: var(--primary-color);
  }
}

@include respond-down('medium') {
  .arrow {
    width: 46px;
    height: 46px;
  }
}
