.index-offer {
  z-index: 1;
  position: relative;
  background: {
    image: url('/static/images/index-offer.jpg');
    size: cover;
    position: center center;
    repeat: no-repeat;
  };
}

.block-offer {
  &__header {
    font-weight: 600;
    line-height: 125%;
    color: #FFFFFF;
  }
  &__description {
    color: var(--grey-font-opacity);
  }
}

@include respond-up('large') {
  .block-offer {
    margin-bottom: 119px;
    &__body {
      min-height: 520px;
      padding: 130px 0;
    }

    &__content {
      max-width: 554px;
    }

    &__header {
      font-size: 40px;
      margin-bottom: 15px;
    }

    &__description {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 35px;
      padding-right: 160px;
    }

    &__button {
      padding: 16px 58px;
    }
  }
}

@include respond('medium') {
  .block-offer {
    margin-bottom: 59px;

    &__body {
      padding: 140px 0;
    }

    &__content {
      max-width: 55%;
    }

    &__header {
      font-size: 30px;
      margin-bottom: 17px;
    }

    &__description {
      margin-bottom: 25px;
    }
  }
}

@include respond-down('medium') {
  .index-offer {
    &::before {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.25);
      width: 100%;
      height: 100%;
    }
  }
  .block-offer {
    &__description {
      font-size: 15px;
      line-height: 155%;
    }
  }
}

@include respond-down('small') {
  .block-offer {
    margin-bottom: 30px;

    &__body {
      padding: 60px 0;
    }

    &__content {
      max-width: 80%;
    }

    &__header {
      font-size: 25px;
      margin-bottom: 14px;
    }

    &__description {
      margin-bottom: 20px;
      max-width: 78%;
    }
  }
}