.product-card {
  display: block;
  cursor: pointer;

  &:hover {
    .product-card__buttons{
      opacity: 1;
    }
  }

  &__top {
    display: block;
    position: relative;
    padding-top: 125%;
    width: 100%;
  }


  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    display: block;
    font-weight: bold;
    line-height: 155%;
  }

  &__price {
    display: block;
    font-weight: 500;
  }

}

@include respond-up('large') {
  .product-card {
    &:hover {
      border-radius: 100%;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(62, 62, 62, 0.494933);
      opacity: 0;
    }

    &__button {
      display: block;
      width: 65px;
      height: 65px;
      background-color: var(--secondary-color);
      border-radius: 100%;
      text-align: center;
      padding-top: 23px;
    }


    &__title {
      font-size: 16px;
      margin: 26px 0 12px;
    }

    &__price {
      font-size: 16px;
      line-height: 20px;
    }

  }
}

@include respond-down('medium') {
  .product-card {

    &__title {
      font-size: 13px;
      margin: 12px 0 10px;
    }

    &__price {
      font-size: 15px;
      line-height: 18px;
    }

    &__buttons {
      display: none;
    }
  }
}

@include respond-down('small') {
  .product-card {

    &__title {
      font-size: 12px;
      margin: 11px 0 3px;
    }

    &__price {
      font-size: 14px;
      line-height: 17px;
    }

    &__buttons {
      display: none;
    }
  }
}