.cart-page {

  &__title {
    font-weight: 600;
  }

  &__products {
    background-color: white;
  }

  &__totals {
    display: flex;
    align-items: center;
    background-color: white;
  }

  &__total {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--grey-color);
    width: 100%;
  }

  &__total-price {
    display: flex;
    align-items: flex-end;
  }

  &__total-value {
    margin-top: 10px;
    font-weight: 600;
    font-size: 15px;
    line-height: 155%;
  }

  &__totals-label {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: var(--label-color);
    padding-right: 5px;
  }

  &__totals-value {
    font-weight: 500;
    font-size: 25px;
    line-height: 120%;
    color: var(--text-color);
  }

  &__bottom {
    margin: auto;
  }

  &__form-block {
    background-color: white;
  }

  &__form-title {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  &__form-num {
    font-weight: 600;
    font-size: 25px;
    line-height: 100%;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 2px solid var(--grey-color);
    height: 52px;
    width: 52px;
    margin-right: 16px;
  }

  &__form-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    color: var(--text-color);
    flex: 0 0 auto;
  }

  &__alert {
    padding: 11px 15px;
    background: #FFF4F4;
    border: 1px solid #FFE3E3;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: var(--alert-color);
    margin-top: 15px;
  }

  &__form-fields {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;

    &_single {
      grid-template-columns: 1fr;
    }
  }

  &__form-field {
    border-bottom: 1px solid var(--black-text);

    input[type="text"] {
      height: 44px;
      border: none;
      background-color: var(--bg-color);
    }

    &_placeholder {
      input[type='text'] {
        &:focus {
          animation: opacity 0.4s;
          transition: var(--default-transition);

          &::placeholder {
            color: black;
          }
        }

        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  &__form-toggle {
    margin-bottom: 35px;

    .radio-list {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-bottom: 8px;
      }

      input {
        display: none;

        &:checked + label {
          color: var(--primary-color);
          border: 1px solid var(--primary-color);
        }

        &[disabled] ~ label {
          pointer-events: none;
        }
      }

      label {
        color: var(--text-color);
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        padding: 8px 15px;
        border: 1px solid var(--grey-color);
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }

  &__form-text {
    font-size: 13px;
    line-height: 120%;
    color: var(--grey-light-color);
    margin-top: 30px;
  }

  &__pickup-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    label {

    }
  }

  &__pickup-phone {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    flex: 0 0 50%;
  }

  &__pickup-email {
    margin-top: 10px;
    color: var(--primary-color);
    font-size: 14px;
    line-height: 155%;
  }

  &__footer {
    margin: 0 auto 28px;
    border: 1px solid #E9E9E9;
    background-color: white;
  }

  &__footer-totals {
    display: flex;
    align-items: stretch;
  }

  &__footer-total {
    flex: 1 1 0;
    padding: 26px 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:not(:last-of-type) {
      border-right: 1px solid #E9E9E9;
    }

    &:first-child {
      .cart-page__footer-value {
        font-size: 25px;
      }
    }
  }

  &__footer-label {
    font-size: 15px;
    line-height: 120%;
    color: var(--label-color);
    margin-bottom: 6px;
  }

  &__footer-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: var(--text-color);
  }

  &__footer-actions {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__submit {
    background-color: var(--primary-color);
    color: white;
    border-radius: 100px;
    padding: 15px 41px;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
  }

  &__agreement {
    font-size: 11px;
    text-align: left;
    line-height: 155%;
  }

  &__pickup-point {
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    align-items: center;
    color: var(--text-color);
    font-size: 15px;
    padding: 10px 0;
  }

  &__pickup-address {
    flex: 0 0 400px;
  }

  &__pickup-worktime {
    font-size: 14px;
    line-height: 125%;
    flex: 0 0 50%;
    text-align: right;
  }

  &__pickup-worktime-element {
    display: flex;
    color: var(--text-color);
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__pickup-worktime-day {
    flex: 0 0 70px;
    color: var(--label-color);
  }

  &__bottom-actions {
    display: flex;
    align-items: center;
  }

  &__total-price-value {
    margin-left: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
  }

  &__offer-title {
    font-weight: 600;
  }

  &__empty {
    display: flex;
    align-items: center;

    .text {
      margin-right: 20px;
    }
  }

  &__time-block {

  }

  &__time-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--black-text);
    margin: 20px 0 16px;
  }
}

@include respond-up('large') {
  .cart-page {

    &__main {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__main-info {
      flex: 0 0 74.5%;
    }

    &__totals-for-tablets {
      display: none;
    }

    &__totals-sticky {
      position: sticky;
      top: 2em;
    }

    &__totals {
      flex-direction: column;
      padding: 30px 28px;
    }

    &__total {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    &__total-label {
      font-size: 15px;
      line-height: 115%;
      color: var(--grey-text-color);
    }

    &__total-value {
      margin-top: 10px;
      font-weight: 600;
      font-size: 15px;
      line-height: 155%;
    }

    &__total-price-number {
      font-weight: 600;
      font-size: 30px;
      line-height: 100%;
    }

    &__bottom {
      margin-top: 80px;
    }

    &__offer-title {
      font-size: 33px;
      line-height: 55px;
    }

    &__form {
      margin-top: 31px;
    }

    &__form-block {
      margin-top: 17px;
      padding: 40px 40px 40px 40px;
    }

    &__bottom-actions {
      margin-top: 35px;
      margin-bottom: 125px;
    }

    &__actions {
      flex: 0 0 39%;
    }

    &__agreement {
      flex: 0 0 50%;
    }

    &__form-name {
      font-size: 20px;
    }

    &__map {
      height: 373px;
    }

  }
}

@include respond-down('medium') {
  .cart-page {

    &__totals {
      padding: 25px 0;
      flex-wrap: wrap;

    }

    &__total {
      padding-bottom: 22px;
      margin-bottom: 22px;
      flex: 0 0 50%;
    }

    &__total-price-number {
      font-size: 22px;
    }

    &__totals-button {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &__bottom {
      margin-top: 50px;
    }

    &__form-block {
      margin-top: 20px;
      padding: 20px 20px 30px;
    }

    &__form-title {
      margin-bottom: 30px;
      font-size: 18px;
    }

    &__form-fields {
      grid-gap: 35px;
    }

    &__totals-sticky {
      display: none;
    }

    &__totals-for-tablets {
      margin-top: 30px;
    }

    &__bottom-actions {
      margin-top: 25px;
      margin-bottom: 50px;
    }

    &__actions {
      flex: 0 0 42%;
    }

    &__submit {
      padding: 13px 40px;
    }

    &__agreement {
      flex: 0 0 50%;
    }

    &__offer-title {
      font-size: 28px;
      line-height: 135%;
    }

    &__form-name {
      font-size: 18px;
    }

    &__map {
      height: 302px;
    }

    &__empty {
      flex-wrap: wrap;

      .text {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }

  }
}

@include respond-down('small') {
  .cart-page {

    &__totals {
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 0;
    }

    &__total {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid var(--grey-color);
    }

    &__offer-title {
      font-size: 23px;
    }

    &__form-fields {
      display: flex;
      flex-direction: column;
    }

    &__form-name {
      font-size: 16px;
    }

    &__form-num {
      height: 45px;
      width: 45px;
      margin-right: 11px;
    }

    &__bottom-actions {
      margin-top: 20px;
      margin-bottom: 30px;
      flex-direction: column;
      align-items: flex-start;
    }

    &__agreement {
      margin-top: 12px;
    }

    &__map {
      height: 310px;
    }

  }
}