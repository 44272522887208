/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767,
    spacer: 20
  ),
  medium: (
    from: 768,
    to: 1199,
    spacer: 30
  ),
  large: (
    from: 1200,
    spacer: 40
  )
);

:root {
  --max-row-width: 1280;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Montserrat", sans-serif;
  --h-font: "Montserrat", sans-serif;

  --t: transparent;

  --primary-color: #44B759;
  --hover-color: #CDE4CD;
  --secondary-color: #FFFFFF;
  --light-green-color: #C0E0BE;
  --background-color: #F4F4F4;
  --grey-border: #E2E2E2;
  --grey-color: #DCE3DC;
  --grey-light-color: #8C8E91;
  --second-grey-color: #787A7D;
  --text-color-dark: #979D97;
  --grey-text-color: #8C8E90;
  --alert-color: #b50000;
  --default-transition: all 0.4s ease-in-out;
  --primary-color-opacity: rgba(1, 199, 103, .85);

  --green-border: #D9F1D8;
  --green-bg: #D7EFD6;
  --grey-font-opacity: rgba(255, 255, 255, 0.62);
  --black-text: #242A32;
  --blue-title: #121B2A;
  --green-shadow: #DDEBDC;

}
