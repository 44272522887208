.error {
  text-align: center;

  &__description {
    padding-bottom: 30px;
  }

  &__404 {
    font-size: 360px;
    font-weight: 800;
    color: var(--primary-color);
  }
}

@include respond('medium') {
  .error {
    padding-top: 100px;

    &__404 {
      font-size: 120px;
    }
  }
}

@include respond('small') {
  .error {
    padding-top: 20px;
    padding-bottom: 60px;

    &__404 {
      font-size: 80px;
    }
  }
}