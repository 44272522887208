.product-index {
  &__list {
    display: grid;
  }

  &__item {
    &:nth-child(1) {
      grid-area: product1;
    }

    &:nth-child(2) {
      grid-area: product2;
    }

    &:nth-child(3) {
      grid-area: product3;
    }

    &:nth-child(4) {
      grid-area: product4;
    }

    &:nth-child(5) {
      grid-area: showButton;
    }
  }

  &__image {
    object-fit: cover;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    display: inline-block;
    font-weight: bold;
    line-height: 155%;
    text-transform: uppercase;
  }

  &__price {
    display: inline-block;
    font-weight: 500;
    line-height: 22px;
  }

  &__link {
    transition: var(--default-transition);
    background-color: var(--green-bg);
    border-radius: 1000px;
    width: 193px;
    height: 193px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover{
      background-color: var(--hover-color);
    }
  }

  &__link-svg {
    margin-bottom: 18px;
  }

  &__link-text {
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

@include respond-up('large') {
  .product-index {
    padding-bottom: 120px;

    &__list {
      grid-template-columns: 612px auto;
      grid-template-areas: 'product1 product2' '. product2' 'product3 product4' 'showButton .';
      grid-column-gap: 72px;
    }

    &__item {
      &:nth-child(1) {
        .product-index__image {
          width: 613px;
          height: 618px;
        }
      }

      &:nth-child(2) {
        padding-top: 179px;
        padding-left: 90px;

        .product-index__image {
          width: 415px;
          height: 600px;
        }
      }

      &:nth-child(3) {
        padding-left: 50px;

        .product-index__image {
          width: 488px;
          height: 512px;
        }
      }

      &:nth-child(4) {
        padding-top: 186px;

        .product-index__image {
          width: 375px;
          height: 435px;
        }
      }

      &:nth-child(5) {
        padding-left: 338px;
      }
    }

    &__title {
      font-size: 23px;
      margin-top: 30px;
    }

    &__price {
      font-size: 18px;
      margin-top: 10px;
    }

    &__link {
      width: 193px;
      height: 193px;
    }

    &__link-text {
      font-size: 12px;
    }
  }
}

@include respond('medium') {
  .product-index {
    padding-bottom: 70px;
    display: flex;
    justify-content: center;

    &__list {
      grid-template-columns: 372px 10px auto;
      grid-template-areas: 'product1 product1 product2' '. . product2' 'product3 product4 product4' 'showButton . .';

    }

    &__item {
      &:nth-child(1) {
        .product-index__image {
          width: 382px;
          height: 384px;
        }
      }

      &:nth-child(2) {
        padding-top: 112px;
        padding-left: 46px;

        .product-index__image {
          width: 259px;
          height: 374px;
        }
      }

      &:nth-child(3) {
        padding-left: 31px;

        .product-index__image {
          width: 304px;
          height: 319px;
        }
      }

      &:nth-child(4) {
        padding-top: 109px;

        .product-index__image {
          width: 234px;
          height: 271px;
        }
      }

      &:nth-child(5) {
        .product-index__button {
          width: 157px;
          height: 157px;
          font-size: 12px;

          &::before {
            top: 55px;
          }
        }

        padding-left: 157px;
      }
    }

    &__image {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 18px;
      margin-top: 18px;
    }

    &__link-text {
      font-size: 12px;
    }

    &__price {
      font-size: 16px;
      margin-top: 5px;
    }
  }
}

@include respond-down('small') {
  .product-index {
    padding-bottom: 49px;

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__item {
      max-width: 335px;
      .product-index__image {
        object-fit: cover;
      }

      &:nth-child(1) {
        .product-index__image {
          width: 335px;
          height: 338px;
        }
      }

      &:nth-child(2) {
        max-width: 228px;
        .product-index__image {
          width: 228px;
          height: 329px;
          margin-top: 30px;
        }
      }

      &:nth-child(3) {
        max-width: 267px;
        .product-index__image {
          width: 267px;
          height: 280px;
          margin-top: 30px;
        }
      }

      &:nth-child(4) {
        max-width: 205px;
        .product-index__image {
          width: 205px;
          height: 239px;
          margin-top: 30px;
        }
      }
    }

    &__image {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 18px;
      margin-top: 16px;
    }

    &__price {
      font-size: 16px;
      margin-top: 5px;
    }

    &__link {
      display: none;
    }
  }
}


