.success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    margin-bottom: 30px;
  }

  &__title {
    font-weight: 600;
    line-height: 125%;
    text-align: center;

  }

  &__subtitle {
    line-height: 155%;
    color: var(--grey-text-color);
    text-align: center;
  }
}

@include respond-up('large') {
  .success-modal {
    padding: 60px 140px;

    &__title {
      font-size: 30px;
      margin-bottom: 20px;
      max-width: 320px;
    }

    &__subtitle {
      font-size: 16px;
      max-width: 420px;

    }
  }
}
@include respond-down('medium') {
  .success-modal {
    padding: 80px 0;
    &__title {
      font-size: 27px;
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 15px;
    }
  }
}