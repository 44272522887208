.success-page {
  box-shadow: 0 0 0 1px #E9E9E9;
  background-color: #fff;

  max-width: 590px;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__icon {
    width: 90px;
    height: 90px;
    display: flex;

    align-items: center;
    justify-content: center;

    background-color: #E6F6EC;

    border-radius: 100px;

    margin-bottom: 30px;

    svg {
      display: block;
    }
  }

  &__head {

  }

  &__head-title {
    color: var(--text-color);
    font-size: 25px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  &__head-subtitle {
    color: var(--label-color);
    font-size: 15px;
  }
}

@include respond-up('medium') {
  .success-page {
    padding: 80px 105px;
  }
}


@include respond-down('small') {
  .success-page {
    padding: 50px 20px;

    &__head-title {
      font-size: 20px;
    }
  }
}