.footer {
  &__nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-border);
  }

  &__nav-wrapper {
    display: flex;
  }

  &__list {
    display: flex;
  }

  &__item {
    &:hover {
      .footer__link {
        &::before {
          left: 0;
          transform-origin: left;
          transform: scaleX(1);
          transition: transform .3s cubic-bezier(.25, .1, .25, 1);
        }
      }
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    line-height: 26px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: black;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .3s ease-out;
    }
  }

  &__number {
    font-weight: 600;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  &__bot {
    display: flex;
    justify-content: space-between;
  }

  &__politic-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__politic-link, &__brand, &__gray {
    color: rgba(0, 0, 0, 0.414006);
    font-size: 13px;
    line-height: 20px;
  }

  &__link-right {
    color: black;
  }
}

@include respond-up('large') {
  .footer {
    &__nav {
      align-items: center;
      padding: 20px 0 35px;
    }

    &__nav-wrapper {
      flex: 0 0 80%;
      align-items: center;
    }

    &__logo {
      padding-right: 50px;
    }

    &__list {
      margin: 0 -12px;
    }

    &__menu-wrapper {
      &_mobile {
        display: none;
      }
    }

    &__item {
      padding: 0 12px;
    }

    &__number {
      font-size: 16px;
      line-height: 26px;
      flex: 0 0 20%;
      display: flex;
      align-items: flex-end;
    }

    &__bot {
      padding: 20px 0 40px;
    }

    &__brand {
    }

    &__politic-link {
    }

    &__politic-wrapper {
      flex: 0 0 32%;
    }

    &__right {
      flex: 0 0 20%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@include respond('medium') {
  .footer {
    &__nav {
      padding: 20px 0 40px;
    }

    &__nav-wrapper {

    }

    &__logo-wrapper {

    }

    &__img {
      width: 100px;
      height: 40px;
    }

    &__logo {
      margin-right: 75px;

    }

    &__menu-wrapper {
      &_mobile {
        display: none;
      }
    }

    &__list {
      flex-wrap: wrap;
      margin: -7px 0;
    }

    &__item {
      flex: 0 0 50%;
      padding: 7px 0;
    }

    &__link {
      &_agency {

      }
    }

    &__number {
      font-size: 15px;
      line-height: 26px;
    }

    &__bot {
      padding: 20px 0;
    }

    &__politic-wrapper {
      flex: 0 0 55%;
    }

    &__brand {
    }

    &__politic {

    }

    &__right {

    }

    &__by {

    }

    &__gray {

    }
  }
}

@include respond-down('small') {
  .footer {
    padding-bottom: 25px;

    &__nav {
      align-items: center;
      border-bottom: none;
      margin-bottom: 25px;
      padding-top: 18px;
    }

    &__nav-wrapper {
      flex-direction: column;
    }

    &__brand {
      margin-bottom: 13px;
    }

    &__logo {

    }

    &__menu-wrapper {
      display: none;

      &_mobile {
        display: flex;
        border-bottom: 1px solid var(--grey-border);
        padding-bottom: 25px;
        margin-bottom: 25px;
      }
    }

    &__list {
      flex-wrap: wrap;
    }

    &__item {
      flex: 0 0 50%;
      margin-bottom: 10px;
    }

    &__link {
      &_agency {

      }
    }

    &__number-wrapper {
      flex: 0 0 50%;
    }

    &__number {
      font-size: 15px;
    }

    &__bot {
      flex-direction: column;
      align-items: center;
    }

    &__politic-wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__politic-link {
      margin-bottom: 13px;
    }

    &__right {

    }

    &__by {

    }

    &__gray {

    }
  }
}