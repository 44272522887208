.smart-tabs {
  list-style: none;
  display: flex;
  justify-content: flex-start;

  li {
    a {
      display: block;
    }
  }
}

.smart-content {
  display: none;

  &._active {
    display: block;
  }
}

.smart-tab {
  &._active {
    border-bottom: 2px solid var(--primary-color);
  }
}