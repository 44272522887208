.contacts-page {
  &__tabs {
    display: none;
  }

  &__tab {
    padding-bottom: 14px;
  }

  &__title {
    font-size: 40px;
    font-weight: 600;
  }

  &__address-link {
    display: block;
    padding: 15px 30px;
    color: var(--grey-text-color);

    &._active {
      color: black;
      border: none;
      background-color: #F8F9F8;
    }
  }

  &__info {

  }

  &__map-box {
    position: relative;
  }

  &__map {
  }

  &__map-list {
    background-color: white;
  }

  &__map-list-name {
    font-weight: 500;
  }

  &__map-list-items {

  }

  &__map-list-item {
    font-weight: 400;
    position: relative;

  }

  &__feedback {

  }
}

@include respond-up('large') {
  .contacts-page {
    &__title {
      margin-bottom: 65px;
    }

    &__address-link {
      display: block;
      padding: 15px 30px;
      color: var(--grey-text-color);

      &._active {
        color: black;
        border: none;
        background-color: #F8F9F8;
      }
    }

    &__body-tabs {
    }

    &__address-link {

      &._active {
      }
    }

    &__info {

    }

    &__map-box {
      margin-bottom: 97px;
    }

    &__map {
      height: 560px;
    }

    &__map-list {
      height: 460px;
      width: 352px;
      top: 50px;
      left: 53px;
    }

    &__map-list-name {
      margin-bottom: 19px;
      font-size: 18px;
      padding: 20px 0 0 33px;
    }

    &__map-list-items {
      height: 394px;
      overflow: scroll;
    }

    &__map-list-item {
      font-size: 15px;

      &::before {
        content: '';
        position: absolute;
        width: 83%;
        height: 1px;
        background-color: var(--grey-color);
        bottom: 0;
        left: 30px;
      }
    }

    &__feedback {

    }
  }
}

@include respond-up('medium') {
  .contacts-page {
    &__map-list {
      position: absolute;

      display: block;

    }
  }
}

@include respond-down('medium') {
  .contacts-page {
    &__title {
      margin-bottom: 35px;
    }

    &__address-link {
      padding: 13px 20px;
    }

    &__body-tabs {
    }

    &__info {

    }

    &__map-box {
      position: relative;
      margin-bottom: 70px;
    }

    &__map {
      height: 339px;

    }

    &__map-list {
      height: 300px;
      width: 287px;
      top: 19px;
      left: 57px;
    }

    &__map-list-name {
      font-size: 16px;
      margin-bottom: 17px;
      padding: 15px 0 0 20px;
    }

    &__map-list-items {
      height: 231px;
      overflow: scroll;
    }

    &__map-list-item {
      font-size: 13px;

      &::before {
        width: 86%;
      }
    }

    &__feedback {

    }
  }
}

@include respond-down('small') {
  .contacts-page {

    &__tabs {
      display: flex;
      justify-content: flex-start;
    }

    &__tab {
      padding-bottom: 14px;
      margin-right: 40px;
      font-size: 17px;
      font-weight: 500;
    }

    &__title {
      margin-bottom: 29px;
    }

    &__info {

    }

    &__map-box {
      margin-top: 30px;
      margin-bottom: 40px;
    }

    &__map-list {
      width: 100%;
      height: 300px;

    }

    &__map {
      z-index: 30;
      width: 100%;

    }

    &__map-list-name {
      margin-bottom: 15px;
    }

    &__map-list-items {
    }

    &__map-list-item {
      &::before {
        width: 88%;
      }
    }

    &__feedback {

    }
  }
}
