h1, h2, h3, h4, h5, h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-weight: var(--h-weight);
  line-height: var(--h-line-height);
}

h1 {
  --h-weight: 600;
  --h-line-height: 125%;
}

h2 {
  --h-weight: 600;
  --h-line-height: 55px;
}

h3 {
  --h-weight: 600;
  --h-line-height: 135%;
}

h4 {
  --h-size: 24px;
}

h5 {
  --h-size: 22px;
}

h6 {
  --h-size: 20px;
}

@include respond-up('large') {
  h1 {
    --h-size: 40px;
    margin-bottom: 67px;
  }

  h2 {
    --h-size: 33px;
    margin-bottom: 35px;
  }

  h3 {
    --h-size: 25px;
  }

  h4 {
    --h-size: 24px;
  }

  h5 {
    --h-size: 22px;
  }

  h6 {
    --h-size: 20px;
  }

}

@include respond-down('medium') {
  h1 {
    --h-size: 30px;
    margin-bottom: 33px;
  }

  h2 {
    --h-size: 28px;
    margin-bottom: 18px;
  }

  h3 {
    --h-size: 23px;
  }

}

@include respond('small') {
  h1 {
    margin-bottom: 18px;
  }
  h3 {
  }

}