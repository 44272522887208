.product-page {
  &__heights-list {
    margin-bottom: 36px;
  }

  &__top {
    display: grid;
  }

  &__slider {
    display: flex;
    grid-area: slider;
    padding-right: 72px;
  }

  &__slider-wrapper {
    position: relative;
    //padding-right: 72px;
  }

  &__item {
    position: absolute;
  }

  &__top-arrows-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
  }

  &__top-arrows {
    display: none;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    padding: 0 10px;
    z-index: 30;
  }

  &__top-arrow {
    background-color: white;
    border-radius: 100%;
  }

  &__heading-name {
    grid-area: heading;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  &__short-description {
    grid-area: description;
    text-align: -webkit-match-parent;
    color: var(--grey-text-color);
    font-style: normal;
    font-weight: normal;
    line-height: 155%;
    font-size: 15px;
  }

  &__more-button {
    display: inline-block;
    color: var(--primary-color);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;

  }

  &__image {
    object-fit: cover;
  }

  &__filter {
    grid-area: filter;
    background-color: var(--secondary-color);
  }

  &__filter-name {
    color: var(--black-text);
    font-weight: 600;
    font-size: 16px;
    line-height: 26/16*1em;
    margin-bottom: 16px;

  }

  &__filter-calculator {
    background-color: var(--background-color);
    display: flex;
    justify-content: space-between;
  }

  &__body-tabs {
    border-bottom: 1px solid #D7D7D7;
  }

  &__bottom-nav {
    display: flex;
    justify-content: space-between;
  }

  &__bottom-arrows {
    display: flex;
  }

  &__bottom-arrow_left {
    margin-right: 10px;
  }

  &__bottom-name {
    font-weight: 600;
  }

  &__bottom-title {
    font-weight: 700;
  }

  &__bottom-price {
    font-weight: 500;
  }

  &__small-image {
    object-fit: cover;
  }
}

@include respond-up('large') {
  .product-page {
    &__top {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr auto;
      grid-template-areas: 'slider heading' 'slider description' 'slider filter';
    }

    &__slider {
      padding-right: 72px;
    }

    &__image {
      width: calc((424 / 1280) * 100vw);
      height: calc((507 / 736) * 100vh);
    }

    &__slider-wrapper {
      &:hover {
        .product-page__top-arrows {
          display: flex;
        }
      }
    }

    &__small-image {
      width: 84px;
      height: 102px;
    }

    &__slider-custommize-item {
      padding: 5px 0;
    }

    &__slider-custommize {
      margin: -5px 15px -5px 0;
    }

    &__heading-name {
      font-size: 35px;
      margin-bottom: 0;
    }

    &__short-description {
      margin-top: 25px;
      font-size: 15px;
    }

    &__more-button {
      margin-top: 20px;
      font-size: 11px;
    }

    &__filter {
      padding: 30px;
      margin-top: 45px;
    }

    &__filter-name {
      font-size: 16px;
      margin-bottom: 16px;
    }

    &__body {
      margin-top: 100px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__body-content {
      padding-top: 40px;
    }

    &__form-container {
      padding: 28px;
      background-color: var(--green-shadow);
    }

    &__form-name {
      margin-bottom: 19px;
      font-size: 23px;
    }

    &__button {
      padding: 16px 40px;
    }

    &__bottom {
      margin-top: 130px;
      margin-bottom: 100px;
    }

    &__bottom-arrows {
      display: none;
    }

    &__bottom-name {
      margin-bottom: 35px;
      font-size: 33px;
    }

    &__bottom-products {
      margin: 0 -11px;

    }

    &__bottom-product {
      padding: 0 11px;

    }

    &__bottom-title {
      margin-top: 20px;
      font-size: 15px;

    }

    &__bottom-price {
      margin-top: 10px;
      font-size: 16px;
    }

    &__button-basket {
      opacity: 0;
      position: absolute;
      left: 87px;
      top: 143px;
      text-align: center;
      padding-top: 20px;
      width: 65px;
      height: 65px;
      border-radius: 100%;
      background-color: var(--secondary-color);
    }

    &__button-product {
      opacity: 0;
      position: absolute;
      left: 168px;
      top: 143px;
      text-align: center;
      padding-top: 24px;
      width: 65px;
      height: 65px;
      border-radius: 100%;
      background-color: var(--secondary-color);
    }
  }
}

@include respond('medium') {
  .product-page {
    &__heights-list {
      margin-bottom: 26px;
    }

    &__top {
      margin-top: 56px;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;
      grid-template-rows: auto 1fr auto;
      grid-template-areas: 'slider heading' 'slider description' 'filter filter';
    }

    &__slider {
      padding: 0px;
    }

    &__image {
      width: calc((300 / 768) * 100vw);
      height: calc((357 / 768) * 100vw);
    }

    &__top-arrows {
      display: flex;
    }

    &__small-image {
      width: calc((59 / 768) * 100vw);
      height: calc((72 / 768) * 100vw);
    }

    &__slider-custommize-item {
      padding: 3px 0;
    }

    &__slider-custommize {
      margin: -3px 10px -3px 0;
    }

    &__heading-name {
      font-size: 28px;
      margin-bottom: 0;
    }

    &__short-description {
      margin-top: 18px;
    }

    &__more-button {
      margin-top: 25px;
    }

    &__filter {
      padding: 26px;
      margin-top: 26px;
    }

    &__filter-calculator {
      margin-top: 30px;
    }

    &__body {
      margin-top: 50px;
    }

    &__body-content {
      padding-top: 30px;
    }

    &__form-container {
      display: none;
    }

    &__bottom {
      margin-top: 76px;
      margin-bottom: 70px;
    }

    &__bottom-name {
      margin-bottom: 25px;
      font-weight: 600;
      font-size: 28px;
    }

    &__bottom-products {
      margin: 0 -9px;
    }

    &__bottom-product {
      padding: 0 9px;
    }

    &__bottom-title {
      margin-top: 12px;
      font-size: 13px;
    }

    &__bottom-price {
      margin-top: 10px;
      font-size: 15px;
    }

    &__button-basket {
      display: none;
    }

    &__button-product {
      display: none;
    }
  }
}

@include respond-down('small') {
  .product-page {

    &__slider {
      padding: 0;
    }

    &__heights-list {
      margin-bottom: 16px;
    }

    &__top {
      margin-top: 20px;
      grid-template-rows: auto auto auto auto;
      grid-template-areas: 'heading' 'slider' 'description' 'filter';
    }

    &__image {
      width: calc((274 / 375) * 100vw);
      height: calc((328 / 375) * 100vw);
    }

    &__small-image {
      width: calc((54 / 375) * 100vw);
      height: calc((66 / 375) * 100vw);
    }

    &__heading-name {
      font-size: 30px;
    }

    &__slider-custommize-item {
      padding: 3px 0;
    }

    &__slider-custommize {
      margin: -3px 10px -3px 0;
    }

    &__heading {
    }

    &__heading-name {
      font-size: 30px;
    }

    &__short-description {
      margin-top: 20px;
    }

    &__more-button {
      margin-top: 20px;
    }

    &__filter {
      padding: 14px;
      margin-top: 31px;
    }

    &__filter-name {
      margin-bottom: 10px;
    }

    &__filter-calculator {
      margin-top: 20px;
      padding: 14px 14px 20px;
    }

    &__body {
      margin-top: 40px;
    }

    &__body-content {
      padding-top: 31px;
    }

    &__form-container {
      display: none;
    }

    &__bottom {
      margin-top: 56px;
      margin-bottom: 40px;
    }

    &__bottom-name {
      margin-bottom: 25px;
      font-weight: 600;
      font-size: 28px;
    }

    &__bottom-products {
      margin: 0 -9px;
    }

    &__bottom-product {
      padding: 0 9px;
    }

    &__bottom-picture {
    }

    &__bottom-title {
      margin-top: 12px;
      font-size: 13px;
    }

    &__bottom-price {
      margin-top: 10px;
      font-size: 15px;
    }

    &__button-basket {
      display: none;
    }

    &__button-product {
      display: none;
    }
  }
}
