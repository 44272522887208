.heights-list {
  ul.radio-list {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;

    li {
      margin: 4px;

      input {
        display: none;

        &:checked + label {
          border-color: var(--primary-color);
          color: var(--primary-color);
        }
      }

      label {
        display: block;
        padding: 8px 15px;
        border: 1px solid var(--grey-color);
        font-weight: 500;
        font-size: 14px;
        line-height: 17/14*1em;
        cursor: pointer;
        transition: var(--default-transition);

        &:hover {
          border-color: var(--primary-color);
        }
    }
    }
  }
}

