.block-about {
  &__title {
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    color: var(--black-text);
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
  }

  &__main-text {
    color: var(--black-text);
  }

  &__text {
    line-height: 155%;
    color: var(--grey-light-color);
  }

  &__link {
    font-style: normal;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 16px;
    border-bottom: 1px solid var(--black-text);
    padding-bottom: 5px;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__advantages-list {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__icon {
    object-fit: contain;
  }
}

@include respond-up('large') {
  .block-about {
    margin-bottom: 146px;

    &__wrapper {
      margin-bottom: 146px;
    }

    &__title {
      font-size: 40px;
      margin-top: 67px;
      margin-bottom: 30px;
    }

    &__content {
      flex: 0 0 37%;
    }

    &__pic {
      max-height: 573px;
      margin-right: 102px;
    }

    &__main-text {
      font-size: 17px;
      line-height: 155%;
      max-width: 481px;
      margin-bottom: 25px;
    }

    &__img {
      box-shadow: -38px 60px 0px 0px var(--green-shadow);
    }

    &__text {
      font-size: 16px;
      margin-bottom: 40px;
    }

    &__advantages-list {
      margin-bottom: 13px;
    }

    &__item {
      max-width: 203px;
    }

    &__icon-wrapper {
      height: 59px;
      margin-bottom: 14px;
    }
  }
}

@include respond('medium') {
  .block-about {
    margin-bottom: 70px;

    &__wrapper {
      justify-content: flex-start;
      margin-bottom: 62px;
    }

    &__title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    &__content {
      flex: 1 0 50%;
    }

    &__pic {
      flex: 0 0 296px;
      margin-right: 46px;
      height: 306px;
    }

    &__img {
      object-fit: cover;
    }

    &__main-text {
      padding-right: 65px;
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 155%;
    }

    &__text {
      font-size: 15px;
      line-height: 155%;
      margin-bottom: 25px;
      padding-right: 25px;
    }

    &__item {
      max-width: 203px;
    }

    &__icon-wrapper {
      height: 59px;
      margin-bottom: 20px;
    }

    &__description {
      padding: 0 28px;
      text-align: center;
      font-size: 16px;
      line-height: 135%;
    }

  }
}

@include respond-down('medium') {
  .block-about {
    &__img {
      box-shadow: -40px 35px 0px 0px var(--green-shadow);
    }
  }
}

@include respond-down('small') {
  .block-about {
    margin-bottom: 40px;

    &__title {
      font-size: 30px;
      margin-bottom: 15px;
    }

    &__wrapper {
      flex-direction: column-reverse;
      align-items: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }

    &__pic {
      width: 296px;
      height: 306px;
      margin-bottom: 63px;
    }

    &__main-text {
      line-height: 155%;
      margin-bottom: 10px;
    }

    &__text {
      font-size: 15px;
      margin-bottom: 20px;
    }

    &__advantages-list {
      flex-wrap: wrap;
    }

    &__item {
      flex: 0 0 49%;
      justify-content: space-between;
      margin-bottom: 30px;
    }

    &__icon-wrapper {
      margin-bottom: 20px;
    }

    &__description {
      padding: 0 28px;
      text-align: center;
    }
  }
}