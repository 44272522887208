.filter-calculator {


  &__sum {
  }

  &__basket-counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__price {
    display: inline-block;
    font-size: 26px;
    font-weight: 600;
  }

  &__value {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;

  }

  &__btn-basket {
  }
}

@include respond-up('large') {
  .filter-calculator {
    padding: 18px 22px;

    &__sum {
    }

    &__basket-counter {
      flex: 0 0 58%;
    }

    &__price {
    }

    &__value {
    }

    &__btn-basket {
    }
  }
}

@include respond-down('medium') {
  .filter-calculator {
    padding: 22px 30px;

    &__sum {
    }

    &__basket-counter {
      flex: 0 0 58%;
    }

    &__price {
    }

    &__value {

    }

    &__btn-basket {
    }
  }
}

@include respond-down('small') {
  .filter-calculator {
    padding: 14px 14px;
    flex-wrap: wrap;

    &__sum {
    }

    &__basket-counter {
      flex: 0 0 100%;
      align-items: center;
    }

    &__price {
    }

    &__value {

    }

    &__btn-basket {
      flex: 0 0 100%;
      margin-top: 15px;
    }
  }
}